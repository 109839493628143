<template>
  <div>
    <header class="py-3 bg-white border-b shadow-md">
      <div class="flex flex-wrap items-center px-4 h-full justify-between">
        <div>
          <navbar-logo/>
        </div>
        <div class="hidden md:flex flex-1 justify-between"></div>
        <!-- <div class="md:flex justify-between items-center space-x-4">
          <language-select clean />
        </div> -->
      </div>
    </header>

    <div
      v-if="isLoaded"
      class="px-4 w-full lg:w-10/12 mx-auto py-8 flex flex-col md:flex-row justify-between items-start space-y-12 md:space-y-0 md:space-x-8 lg:space-x-20"
    >

      <div class="w-full md:w-2/3">
        <purchase-summary class="md:hidden" />
        <wizard-steps
          :steps="steps"
          :current="currentIndex"
          @step-change="setCurrentIndex"
        />

        <keep-alive>
          <component
            :is="currentComponent"
            @final-step="finishWizard"
          />
        </keep-alive>

        <div class="w-full my-6 flex justify-between">
          <div>
            <button
              @click.prevent="previousTab"
              v-if="!isFirstStep && !isLastStep"
              type="button"
              class="btn ghost"
            >
              {{ $t('action.back') }}
            </button>
          </div>

          <div>
            <button
              v-if="!isLastStep"
              @click.prevent="nextTab"
              type="button"
              class="btn btn-primary"
            >
              {{ $t('action.continue') }}
            </button>
          </div>
        </div>

        <div v-if="isLastStep" class="w-full my-6 flex justify-center">
          <button
            @click.prevent="finishWizard"
            type="button"
            class="btn btn-larger btn-primary py-4 bg-gradient-to-r from-blue-500 to-blue-700 w-full"
            :disabled="isSending"
          >
            <template v-if="isSending">
              <i class="fas fa-spinner fa-spin"></i>
              {{ $t('sentence.sending_order') }}
            </template>
            <template v-else>
              <i class="fas fa-shopping-cart"></i>
              {{ $t('action.buy_now') }}
            </template>
          </button>
        </div>
      </div>
      <div class="w-full md:w-1/3">
        <div class="flex flex-col justify-center items-center space-y-8 w-full">
          <div class="w-full space-y-8 hidden md:block">
            <p class="font-semibold text-base md:text-lg">
              Resumo da compra
            </p>
            <purchase-summary />
            <installment-options v-if="!useMonthlyValue" />
            <coupon-options />
            <hr>
            <total-payable />
          </div>
          <div class="flex justify-center md:justify-end w-full space-x-4">
            <div v-for="(flag, index) in paymentFlags" :key="index">
              <i
                class="text-gray-400"
                :class="flags[flag]"
              ></i>
            </div>
          </div>
          <div class="text-xs font-light space-y-2">
            <p>Ao fazer seu pedido, você concorda com os <a href="https://docs.google.com/document/d/12pX4ow7LdqkEU4Qm9ckOGBev24zTn4ATlY6HjJtGsKk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">termos de serviço</a> e a <a href="https://docs.google.com/document/d/12pX4ow7LdqkEU4Qm9ckOGBev24zTn4ATlY6HjJtGsKk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">política de privacidade</a>.</p>
            <p>Ao confirmar sua subscrição, você permite que MANYCONTENT cobre seu cartão por esse pagamento e pagamentos futuros de acordo com as condições.</p>
          </div>
          <!-- <pre>{{checkoutFormData}}</pre> -->
        </div>
      </div>
    </div>
    <div
      class="flex flex-col justify-center items-center mx-auto"
      v-else
    >
      <div class="my-24">
        <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
      </div>
    </div>
  </div>

  <base-modal
    v-model:open="isModalOpen"
    hide-footer
    size="medium"
    hide-button
  >
    <div class="card-header flex-col">
      <p v-if="profiles">
        Você possui <strong>{{ profiles.length }}</strong> perfis na Manycontent.
        <br>
        Essa nova assinatura deve ser atribuida a um novo perfil ou a um perfil existente?
      </p>
    </div>
    <div class="card-body overflow-y-auto max-h-80">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li
          class="px-4 py-4 text-sm hover:bg-gray-200 flex gap-4 items-center cursor-pointer"
        >
          <input type="radio" name="page" id="new" value="" v-model="selectedProfileId">
          <label class="w-full cursor-pointer" for="new">
            <div class="font-semibold">Criar um perfil</div>
            Quero criar um novo perfil na Manycontent
          </label>
        </li>
        <li
          v-for="profile in profiles"
          :key="profile.id"
          class="px-4 py-4 text-sm hover:bg-gray-200 flex gap-4 items-center cursor-pointer"
        >
          <input type="radio" name="page" :id="profile.id" :value="profile.id" v-model="selectedProfileId" :disabled="profile.subscription?.interval === 'year'">
          <label class="w-full cursor-pointer" :for="profile.id">
            <div class="flex items-center gap-2">
              <span class="font-semibold text-lg">{{ profile.name }}</span>
              <span
                class="badge-outline text-sm text-green-600"
                v-if="profile.subscription?._active"
              >
                {{ $t('sentence.active') }}
              </span>
              <span
                class="badge-outline text-sm text-red-700"
                v-if="!profile.subscription?._active"
              >
                {{ $t('sentence.inactive') }}
              </span>
            </div>
            <span v-if="profile.subscription?._active">Quero alterar a assinatura desse perfil</span>
            <span v-if="!profile.subscription?._active">Quero reativar esse perfil</span>
            <span v-if="profile.subscription?._active && profile.subscription?.interval === 'year'">Esse perfil não pode ser alterado pois possui uma assinatura anual</span>
          </label>
        </li>
      </ul>
    </div>
    <div class="card-footer flex justify-end items-center space-x-2">
      <button
        class="btn btn-primary"
        :disabled="selectedProfileId === null"
        @click.prevent="handleSelectProfile"
      >
        Continuar
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, onMounted, readonly, ref, unref, watch } from 'vue'
import NavbarLogo from '@/components/dashboard/navbar/NavbarLogo.vue'
import WizardSteps from '@/components/wizard/WizardSteps.vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import BaseInput from '@/components/base/BaseInput.vue'
import MultiSelect from '@vueform/multiselect'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useNumeral } from '@/composables/useNumeral'
import languageSelect from '@/components/settings/languageSelect.vue'
import { useToast } from '@/composables/useToast'
import PurchaseSummary from '@/components/pages/checkout/PurchaseSummary.vue'
import InstallmentOptions from '@/components/pages/checkout/InstallmentOptions.vue'
import CouponOptions from '@/components/pages/checkout/CouponOptions.vue'
import useVuelidate from '@vuelidate/core'
import { useToggle } from '@/composables/useToggle'
import { fbPixel } from '@/plugins/facebook/pixel'
import TotalPayable from '@/components/pages/checkout/TotalPayable.vue'
import { currentUser } from '@/composables/user/currentUser'
import BaseModal from '@/components/base/BaseModal.vue'
// import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  components: { BaseModal, NavbarLogo, WizardSteps, BaseInput, MultiSelect, languageSelect, PurchaseSummary, InstallmentOptions, CouponOptions, TotalPayable },
  setup () {
    const profiles = ref<any[]>()
    const selectedProfileId = ref<string | null>(null)
    // @ts-ignore
    // const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    
    const router = useRouter()

    const { user } = currentUser()
    
    const { t } = useI18n()

    const { format } = useNumeral()

    const {
      isLoaded,
      installments,
      products,
      total,
      paymentFlags,
      checkoutFormData,
      submitCheckoutStatus,
      checkoutCode,
      useMonthlyValue,
      fetchCheckoutInfo,
      submitCheckoutData,
      saveLead,
      researchUserProfiles,
      updateCheckoutFormData,
      isInternational,
    } = useCheckout()

    const {
      isActive: isSending,
      setActive,
      setInactive
    } = useToggle(false)

    const {
      isActive: isModalOpen,
      // setActive: openModal,
      setInactive: closeModal
    } = useToggle(false)

    const flags: {[key: string]: string} = readonly({
      'visa': 'fab fa-cc-visa',
      'mastercard': 'fab fa-cc-mastercard',
      'american-express': 'fab fa-cc-amex',
      'discover': 'fab fa-cc-discover',
      'diners-club': 'fab fa-cc-diners-club',
      'jcb': 'fab fa-cc-jcb',
      'boleto': 'fas fa-barcode'
    })
    
    const currentIndex = ref(0)

    const tabs = computed(() => {
      return [
        {
          component: defineAsyncComponent(() => import('@/components/pages/checkout/ContactCheckout.vue')),
          title: t('page.checkout.sentence_contact'),
          rules: {},
          visible: true
        },
        {
          component: defineAsyncComponent(() => import('@/components/pages/checkout/AddressCheckout.vue')),
          title: t('page.checkout.sentence_address'),
          rules: {},
          visible: !isInternational.value
        },
        {
          component: defineAsyncComponent(() => import('@/components/pages/checkout/PaymentCheckout.vue')),
          title: t('page.checkout.sentence_payment'),
          rules: {},
          visible: true
        }
      ].filter((tab) => tab.visible)
    })

    const steps = computed(() => {
      return tabs.value.map((tabs, index) => ({
          title: tabs.title,
          key: `step-${index}`,
          index
        }))
    })

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index
    }

    const currentTab = computed(() => {
      return tabs.value[unref(currentIndex)]
    })

    const currentComponent = computed(() => {
      return unref(currentTab).component
    })

    const isFirstStep = computed(() => unref(currentIndex) === 0)

    const isLastStep = computed(() => unref(currentIndex) === steps.value.length - 1)

    const previousTab = async () => {
      if (unref(currentIndex) > 0) {
        currentIndex.value--
      }
    }

    const v$ = useVuelidate()

    const isFormValid = () => {
      v$.value.$touch()
      v$.value.$validate()
      return !v$.value.$invalid
    }

    const nextTab = () => {
      if (currentIndex.value < steps.value.length - 1) {
        if (!isFormValid()) {
          // const { $property, $validator } = v$.value.$errors[0]
          // console.log($property, $validator)
          return
        }
        
        currentIndex.value++
      }
    }

    const handleUserProfilesList = async () => {
      profiles.value = await researchUserProfiles()
    }

    watch(currentIndex, async (after, before) => {
      if (before === 0 && after === 1) {
        saveLead()
        await handleUserProfilesList()
      }
    })

    // const handleFinishWizard = async () => {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await recaptchaLoaded()

    //   // Execute reCAPTCHA with action "submit".
    //   const token = await executeRecaptcha('submit')

    //   console.log(token)
    // }

    const finishWizard = async () => {
      const getProfilesResume = () => {
        const resume = {
          profilesCount: profiles.value?.length || 0,
          activeProfilesCount: 0,
          inactiveProfilesCount: 0,
        }

        resume.inactiveProfilesCount = (profiles.value?.filter(profile => profile._active === false))?.length || 0
        resume.activeProfilesCount = (profiles.value?.filter(profile => profile._active === true))?.length || 0

        return resume
      }


      if (isFormValid()) {
        if (profiles.value && profiles.value.length) {
          const resume = getProfilesResume()
  
          if (resume.profilesCount === 1 && resume.profilesCount === resume.inactiveProfilesCount) {
            selectedProfileId.value = profiles.value?.[0].id
          }

          // if (!selectedProfileId.value) {
          //   openModal()
          //   return
          // }
        }

        handleSubmitOrder(selectedProfileId.value || '')
      }
    }

    const handleSelectProfile = async () => {
      handleSubmitOrder(selectedProfileId.value || '')
    }

    const handleSubmitOrder = async (profileId?: string) => {
      setActive()
        
      await submitCheckoutData(profileId)
      
      if (submitCheckoutStatus.value.success) {
        useToast.fire({
          icon: 'success',
          title: t('state.checkout_success.title'),
          text: t('state.checkout_success.message')
        })
        router.replace({ name: 'thanks' })
      } else {
        useToast.fire({
          icon: 'error',
          title: 'Ops',
          text: submitCheckoutStatus.value.error
        })
        setInactive()
      }
    }

    const getValidPhoneNumber = (input: string) => {
      let phone = input.replace(/[^0-9]/g,'')
      return phone
      // phone = phone.substring(phone.length - 11)
      // if (phone.length < 11) {
      //   return null
      // }
      // const [areaCode, phoneNumber] = [phone.slice(0, 2), phone.slice(2)]
      // return `(${areaCode}) ${phoneNumber}`
    }

    const getQueryStringData = () => {
      const { email, name, phone, profile } = router.currentRoute.value.query

      const payload = { 
        ...(email && {email}),
        ...(name && {name}),
        ...(phone && {phone: getValidPhoneNumber(phone as string)})
      }

      updateCheckoutFormData(payload)

      if (profile) {
        selectedProfileId.value = profile as string
      }
    }

    const getLoggedCustomerInfo = () => {
      let phoneNumber
      if (unref(user).billing?.customer?.phone) {
        phoneNumber = getValidPhoneNumber(unref(user).billing?.customer?.phone)
      }


      if (unref(user).id) {
        const payload = { 
          ...(unref(user).email && {email: unref(user).email}),
          ...(unref(user).name && {name: unref(user).name}),
          ...(phoneNumber && {phone: phoneNumber})
        }
  
        if (Object.keys(payload).length > 0) {
          updateCheckoutFormData(payload)
        }
      }
    }

    onMounted( () => {
      fetchCheckoutInfo(checkoutCode.value)
      fbPixel('track', 'PageView')
      fbPixel('track', 'InitiateCheckout')
      getQueryStringData()
      getLoggedCustomerInfo()
    })

    return {
      isLoaded,
      format,
      currentIndex,
      tabs,
      steps,
      setCurrentIndex,
      currentComponent,
      isFirstStep,
      isLastStep,
      previousTab,
      nextTab,
      finishWizard,
      // handleFinishWizard,
      installments,
      products,
      total,
      paymentFlags,
      flags,
      checkoutFormData,
      useMonthlyValue,
      isSending,
      isModalOpen,
      closeModal,
      profiles,
      selectedProfileId,
      handleSelectProfile
    }
  }
})
</script>
